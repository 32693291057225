@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@400;600&family=Merriweather:wght@400;700;900&display=swap");
@import "breakpoints";

$border-radius: 8;
$big-gap: 6rem;
$beige: lighten(rgb(213, 182, 146), 16%);
$text-beige: darken($beige, 60%);
$purple: rgb(125, 110, 131);
$text-purple: lighten($purple, 52%);
$gray: lighten(rgb(231, 231, 231), 4%);
$text-gray: darken($gray, 56%);

html * {
  font-family: "Merriweather Sans", sans-serif;
  line-height: 1.6;
  color: $text-beige;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Merriweather", serif;
  margin-bottom: 0.68rem;
}

h1 {
  font-size: 1.8em;

  @include breakpoint("lg") {
    font-size: 2.2em;
  }

  @include breakpoint("xl") {
    font-size: 2.6em;
  }
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.6em;
}

img {
  border-radius: $border-radius;
  object-fit: cover;
}

iframe {
  border-radius: $border-radius;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
}

button {
  border-radius: $border-radius;
  border: none;
  padding: 0.4rem 0.8rem;
}

.app {
  overflow-x: hidden;
  padding: 0 2rem;

  @include breakpoint("md") {
    padding: 0 6rem;
  }
}

.nav {
  display: flex;
  justify-content: center;

  @include breakpoint("md") {
    justify-content: start;
  }

  &__logo {
    width: 192;
  }
}

section:first-child {
  padding-top: 0;
  margin-top: 0;
  text-align: center;

  @include breakpoint("md") {
    text-align: left;
    padding-top: 2rem;
  }
}

section:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

section:nth-child(even) {
  flex-direction: column-reverse;

  @include breakpoint("md") {
    flex-direction: row;
  }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.8rem;
  margin: 2rem 0;
  padding: 2rem 0;
  position: relative;

  @include breakpoint("md") {
    flex-direction: row;
    gap: $big-gap;
  }

  &__media {
    width: 100%;
    height: 340;

    iframe {
      height: 600;
    }

    @include breakpoint("sm") {
      width: 100%;
      height: 400;
    }

    @include breakpoint("md") {
      height: 600;
    }
  }

  &__text {
    width: 100%;
  }

  &__helper-text {
    position: absolute;
    color: $beige;
    font-weight: 900;
    z-index: -10;
    opacity: 46%;
    font-size: 5em;
    transform: translate(-2rem, -1.8rem);
    display: none;

    @include breakpoint("md") {
      display: block;
    }

    @include breakpoint("lg") {
      font-size: 6.2em;
      transform: translate(-2rem, -2.6rem);
    }
  }

  &__description {
    text-align: justify;
    font-size: 0.98em;

    @include breakpoint("md") {
      font-size: 1.2em;
    }
  }

  &__button {
    padding: 0.6rem 1.2rem;
    border-radius: 1.6rem;
    font-size: 1.2em;
    font-weight: 600;
    margin: 2rem 0;
    background-color: $gray;
    color: $text-gray;
    display: flex;
    justify-content: center;
    gap: 0.68rem;
    align-items: center;
    transition: filter 0.6s;
    width: 100%;

    @include breakpoint("md") {
      width: auto;
      padding: 0.8rem 1.6rem;
      margin: 3.2rem 0;
    }

    & > svg {
      width: 24px;
      stroke: $text-gray;
      stroke-width: 1.8;
    }

    &:hover,
    &:focus {
      border: none;
      outline: none;
      filter: drop-shadow(0 0 16px darken($gray, 6%));
    }
  }
}

.hero {
  &__header {
    display: none;

    @include breakpoint("md") {
      display: initial;
    }
  }

  &__description {
    display: none;

    @include breakpoint("lg") {
      display: block;
    }

    &--mobile {
      display: initial;
      font-size: 1.12em;

      @include breakpoint("lg") {
        display: none;
      }
    }
  }

  &__blob {
    position: absolute;
    z-index: -10;

    &--1 {
      opacity: 6%;
      display: none;

      @include breakpoint("lg") {
        display: initial;
      }
    }

    &--2 {
      opacity: 14%;
      right: 0;
      bottom: 0;
      height: 70%;
      transform: scale(132%);

      @include breakpoint("sm") {
        opacity: 24%;
        bottom: 4rem;
      }

      @include breakpoint("md") {
        bottom: 0;
      }
    }

    &--3 {
      margin-top: -4rem;
      margin-right: -14rem;
      opacity: 8%;
      transform: scale(200%);

      @include breakpoint("md") {
        transform: scale(140%);
        opacity: 4%;
      }

      @include breakpoint("lg") {
        top: -12rem;
        transform: scale(120%);
      }

      @include breakpoint("xl") {
        transform: scale(80%);
      }
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 2;
  font-size: 1.2em;
  &-item {
    display: flex;
    align-items: center;
    &__label {
      font-size: 0.6em;
      margin-right: 0.8rem;
      font-weight: 700;
      padding: 0.2rem 0.54rem;
      border-radius: $border-radius;
      background-color: $beige;
    }
  }
}

.contact {
  &__description {
    font-size: 1.12em;
  }
}

.price-list {
  position: relative;

  @include breakpoint("md") {
    margin-top: 3.2rem;
  }

  &__description {
    font-size: 1.2em;
  }
}

.footer {
  margin-top: 8rem;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  @include breakpoint("md") {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__signature {
    font-size: 0.8em;
    opacity: 40%;
  }
}
