@import "https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@400;600&family=Merriweather:wght@400;700;900&display=swap";
html * {
  color: #614625;
  font-family: Merriweather Sans, sans-serif;
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: .68rem;
  font-family: Merriweather, serif;
}

h1 {
  font-size: 1.8em;
}

@media screen and (min-width: 992px) {
  h1 {
    font-size: 2.2em;
  }
}

@media screen and (min-width: 1200px) {
  h1 {
    font-size: 2.6em;
  }
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.6em;
}

img {
  object-fit: cover;
  border-radius: 8px;
}

iframe {
  border-radius: 8px;
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  border: none;
  border-radius: 8px;
  padding: .4rem .8rem;
}

.app {
  padding: 0 2rem;
  overflow-x: hidden;
}

@media screen and (min-width: 768px) {
  .app {
    padding: 0 6rem;
  }
}

.nav {
  justify-content: center;
  display: flex;
}

@media screen and (min-width: 768px) {
  .nav {
    justify-content: start;
  }
}

.nav__logo {
  width: 192px;
}

section:first-child {
  text-align: center;
  margin-top: 0;
  padding-top: 0;
}

@media screen and (min-width: 768px) {
  section:first-child {
    text-align: left;
    padding-top: 2rem;
  }
}

section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

section:nth-child(2n) {
  flex-direction: column-reverse;
}

@media screen and (min-width: 768px) {
  section:nth-child(2n) {
    flex-direction: row;
  }
}

.section {
  flex-direction: column;
  align-items: center;
  gap: 1.8rem;
  margin: 2rem 0;
  padding: 2rem 0;
  display: flex;
  position: relative;
}

@media screen and (min-width: 768px) {
  .section {
    flex-direction: row;
    gap: 6rem;
  }
}

.section__media {
  width: 100%;
  height: 340px;
}

.section__media iframe {
  height: 600px;
}

@media screen and (min-width: 576px) {
  .section__media {
    width: 100%;
    height: 400px;
  }
}

@media screen and (min-width: 768px) {
  .section__media {
    height: 600px;
  }
}

.section__text {
  width: 100%;
}

.section__helper-text {
  color: #ecddcd;
  z-index: -10;
  opacity: .46;
  font-size: 5em;
  font-weight: 900;
  display: none;
  position: absolute;
  transform: translate(-2rem, -1.8rem);
}

@media screen and (min-width: 768px) {
  .section__helper-text {
    display: block;
  }
}

@media screen and (min-width: 992px) {
  .section__helper-text {
    font-size: 6.2em;
    transform: translate(-2rem, -2.6rem);
  }
}

.section__description {
  text-align: justify;
  font-size: .98em;
}

@media screen and (min-width: 768px) {
  .section__description {
    font-size: 1.2em;
  }
}

.section__button {
  color: #626262;
  width: 100%;
  background-color: #f1f1f1;
  border-radius: 1.6rem;
  justify-content: center;
  align-items: center;
  gap: .68rem;
  margin: 2rem 0;
  padding: .6rem 1.2rem;
  font-size: 1.2em;
  font-weight: 600;
  transition: filter .6s;
  display: flex;
}

@media screen and (min-width: 768px) {
  .section__button {
    width: auto;
    margin: 3.2rem 0;
    padding: .8rem 1.6rem;
  }
}

.section__button > svg {
  width: 24px;
  stroke: #626262;
  stroke-width: 1.8px;
}

.section__button:hover, .section__button:focus {
  filter: drop-shadow(0 0 16px #e2e2e2);
  border: none;
  outline: none;
}

.hero__header {
  display: none;
}

@media screen and (min-width: 768px) {
  .hero__header {
    display: initial;
  }
}

.hero__description {
  display: none;
}

@media screen and (min-width: 992px) {
  .hero__description {
    display: block;
  }
}

.hero__description--mobile {
  display: initial;
  font-size: 1.12em;
}

@media screen and (min-width: 992px) {
  .hero__description--mobile {
    display: none;
  }
}

.hero__blob {
  z-index: -10;
  position: absolute;
}

.hero__blob--1 {
  opacity: .06;
  display: none;
}

@media screen and (min-width: 992px) {
  .hero__blob--1 {
    display: initial;
  }
}

.hero__blob--2 {
  opacity: .14;
  height: 70%;
  bottom: 0;
  right: 0;
  transform: scale(1.32);
}

@media screen and (min-width: 576px) {
  .hero__blob--2 {
    opacity: .24;
    bottom: 4rem;
  }
}

@media screen and (min-width: 768px) {
  .hero__blob--2 {
    bottom: 0;
  }
}

.hero__blob--3 {
  opacity: .08;
  margin-top: -4rem;
  margin-right: -14rem;
  transform: scale(2);
}

@media screen and (min-width: 768px) {
  .hero__blob--3 {
    opacity: .04;
    transform: scale(1.4);
  }
}

@media screen and (min-width: 992px) {
  .hero__blob--3 {
    top: -12rem;
    transform: scale(1.2);
  }
}

@media screen and (min-width: 1200px) {
  .hero__blob--3 {
    transform: scale(.8);
  }
}

.list {
  flex-direction: column;
  gap: 2px;
  font-size: 1.2em;
  display: flex;
}

.list-item {
  align-items: center;
  display: flex;
}

.list-item__label {
  background-color: #ecddcd;
  border-radius: 8px;
  margin-right: .8rem;
  padding: .2rem .54rem;
  font-size: .6em;
  font-weight: 700;
}

.contact__description {
  font-size: 1.12em;
}

.price-list {
  position: relative;
}

@media screen and (min-width: 768px) {
  .price-list {
    margin-top: 3.2rem;
  }
}

.price-list__description {
  font-size: 1.2em;
}

.footer {
  flex-direction: column;
  gap: .2rem;
  margin-top: 8rem;
  padding: 2rem 0;
  display: flex;
}

@media screen and (min-width: 768px) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.footer__signature {
  opacity: .4;
  font-size: .8em;
}

/*# sourceMappingURL=index.baae1fb3.css.map */
